<template>
    <div class="guide-container">
        <div class="pc-search-img pc" />
        <div class="guide-message pc" v-html="getPcMsg"></div>

        <div class="mobile-search-img mobile" />
        <div class="guide-message mobile" v-html="getMobileMsg"></div>
    </div>
</template>

<script>
export default {
    name: 'SearchGuide',
    props: {
        guideMessage: {
            type: String,
            default: ''
        }
    },
    computed: {
        getPcMsg: function () {
            if (this.guideMessage) return this.guideMessage
            else return ' 選擇查詢條件並按下套用按鈕<br/>以獲得資料'
        },
        getMobileMsg: function () {
            if (this.guideMessage) return this.guideMessage
            else
                return '<span>請點選「右上角」放大鏡</span><br/>選擇查詢條件並按下套用按鈕<br/>以獲得資料'
        }
    }
}
</script>

<style lang="scss" scoped>
.guide-container {
    display: block;
    justify-content: center;
    width: 100%;
    margin: 80px 0;
    @media screen and (max-width: 576px) {
        margin: 0 0;
    }

    -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
    animation: fadeInDown 500ms ease-in-out;

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translateY(40px);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }
}
.pc {
    display: block;
    @media screen and (max-width: 576px) {
        display: none;
    }
}

.mobile {
    display: none;
    @media screen and (max-width: 576px) {
        display: block;
    }
}

.pc-search-img {
    width: 218px;
    height: 181px;
    margin: auto;
}
.mobile-search-img {
    width: 218px;
    height: 220px;
    margin: auto;
}

.guide-message {
    font-size: 15px;
    color: $secondary-grey;
    text-align: center;
    margin-top: 20px;
    :deep(span) {
        color: $fourth-blue;
        font-size: 18px;
    }
}
</style>
